@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-text {
  white-space: pre-wrap;

  &__title {
    letter-spacing: 0.02em;
    color: $color-black-900;
    font-size: 24px;
  }

  &__sidebar-title {
    letter-spacing: 0.02em;
    color: $color-black-500;
    font-size: 20px;
  }
}
