@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-my-profile {
  width: 100%;
  max-width: 450px;
  margin: 32px auto 32px auto;
  border-radius: 16px;
  background-color: $color-white;
  box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);

  &__info {
    padding: 16px 16px 32px 16px;
    background: #fff;
  }

  &__avatar {
    width: 150px;
    height: 150px;
    object-fit: contain;

    margin: 0 auto;
    display: block;

    border: 5px solid #fff;
    border-radius: 50%;

    -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  }
}
