@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.image-lazy {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    animation: loading-animation 10s infinite;
  }

  @keyframes loading-animation {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: #ccc;
    }
    100% {
      background-color: #fff;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
