@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.social-item {
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  border-radius: 16px;

  background-color: #fff;

  border: 1px solid #c1c1c1;

  -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);

  transition: all 0.2s ease-in;

  @include mobileScreen {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: black;
    padding: 0 24px;
    text-decoration: none;
  }

  &-wrapper &-icon img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  &-wrapper p {
    font-size: 20px;
  }
}
