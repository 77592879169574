@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-button-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin-top: 4px;
  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + &__slider {
    background-color: $primary;
  }
  input:focus + &__slider {
    box-shadow: 0 0 1px $primary;
  }
  input:checked + &__slider::before {
    transform: translateX(20px);
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  &__slider::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}
