@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.cmp-phoneinput {
  &__input--error {
    .PhoneInputCountry,
    input {
      border-color: $color-danger;
    }
  }

  &__input--error.PhoneInput--focus {
    .PhoneInputCountry,
    input {
      box-shadow: $error-shadow;
    }
  }
}
