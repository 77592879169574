@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-navbar {
  box-shadow: $default-shadow;

  &.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    left: 0;
    width: calc(100%);
    // TODO: use this when using sidebar
    // left: $sidebar-width;
    // width: calc(100% - #{$sidebar-width});

    // TableFunc has z-index is 101, Loading has z-index 102
    z-index: 103;
    // transition: all 0.1s ease-in-out;
  }

  &.navbar.is-collapse-sidebar {
    left: 0;
    width: calc(100%);
    // TODO: use this when using sidebar
    // left: $sidebar-mini-width;
    // width: calc(100% - #{$sidebar-mini-width});
  }

  &.navbar.is-open-menu {
    width: calc(100% - 10px);
  }

  &__container {
    width: 100%;
    flex-direction: row;
    margin: 0 36px;
    justify-content: space-between;

    @include mobileScreen {
      margin: 0 16px;
    }
  }

  &__start {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__end {
    align-items: center;

    @include desktopScreen {
      transform: translateX(-24px);
    }

    &--item {
      height: $navbar-height;
      line-height: $navbar-height;
      margin: 4px 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      cursor: pointer;

      &--avatar {
        border: 2px solid #ebebeb;
        border-radius: 50%;
        background: radial-gradient(60.42% 60.42% at 52.6% 50%, rgba(226, 72, 127, 0.76) 0%, #b10f49 100%);

        -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
        box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
      }

      &--link {
        color: $color-text-gray;
        position: relative;
        font-weight: $font-weight-medium;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0%;
          height: 4px;
          background-color: $color-primary;
          margin: auto;
          transition: $default-transition;
        }

        @include hover {
          color: $color-text;
          text-decoration: none;
        }

        @include focus {
          color: $color-text;
          text-decoration: none;
        }
      }

      &--disabled {
        display: none;
      }

      &--active {
        text-decoration: none;
        color: $color-text;
      }
    }
  }

  &-menu-popover {
    top: 3.75rem !important;
    min-width: 250px !important;

    & .MuiMenuItem-root {
      padding: 12px !important;

      & .MuiListItemIcon-root {
        min-width: 36px !important;
      }
    }
  }
  &__img {
    object-fit: contain;
    height: 50px;
  }
}
