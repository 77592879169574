@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'OldStandardTT';
  src: local('OldStandardTT'), url(./OldStandardTT-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'OldStandardTT';
  src: local('OldStandardTT'), url(./OldStandardTT-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'OldStandardTT';
  src: local('OldStandardTT'), url(./OldStandardTT-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
