@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-validate-password {
  font-size: 14px;
  transition: 0.4s;
  &--show &__item {
    text-align: left;
    height: 1.8rem;
    @include mobileScreen {
      height: 2.8rem;
    }
  }
  &__item {
    transition: 0.1s;
    height: 0;
    overflow: hidden;
    @include mobileScreen {
      align-items: flex-start;
    }
    > i {
      font-size: 18px;
      font-weight: 700;
    }
    &--valid {
      color: $color-success;
    }
    &--invalid {
      color: $color-danger;
    }
  }
}
