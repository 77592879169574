@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.form-element {
  width: 100%;
  text-align: left;

  &.text-field-style {
    position: relative;

    & label {
      position: absolute;
      top: -10px;
      left: 8px;
      z-index: 1;
      background-color: $color-white;
      color: #757575;
      padding: 0 4px 0 4px;
      font-size: 12px;
      font-weight: $font-weight-regular;
    }
  }

  label {
    margin-bottom: 4px;
  }

  &__error {
    margin-top: 4px;
    color: $color-danger;
    font-size: 14px;
  }
}
