@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.edit-my-profile {
  width: 100%;
  margin: 60px auto;

  @include tabletScreen {
    margin: 24px auto;
  }

  &__info {
    padding: 8px 16px 24px 16px;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  }

  &__tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
  }

  &__avatar {
    width: 150px;
    height: 150px;
    object-fit: contain;

    margin: 0 auto;
    display: block;

    border: 5px solid #fff;
    border-radius: 50%;

    -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  }

  &-tab-info {
    width: 50%;
    margin: 0 auto;

    @include tabletScreen {
      width: 100%;
    }

    &__avatar {
      width: 150px;
      height: 150px;
      object-fit: contain;

      margin: 0 auto;
      display: block;

      border: 5px solid #fff;
      border-radius: 50%;

      -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
      box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    }
  }

  &-tab-links {
    width: 50%;
    margin: 0 auto;

    @include tabletScreen {
      width: 100%;
    }
  }

  &__preview {
    width: 100%;
    margin: 0 auto;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    border: 3px solid rgba(0, 0, 0, 0.25);
    padding: 16px 24px;
    max-width: 410px;
  }
}

.so-cial {
  &-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &-empty {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    padding: 16px;
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  }
}
