@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-info {
  width: 100%;
  max-width: 410px;
  margin: 0 auto;

  &__preview {
    width: 100%;
    margin: 0 auto;
    // background-color: $color-white;
    border-radius: 8px;
    // box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    padding: 16px 24px 32px 24px;
    max-width: 410px;
  }

  &__avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin: 0 auto;
    display: block;

    border: 5px solid #fff;
    border-radius: 50%;

    -webkit-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: -1px 3px 3px -1px rgba(0, 0, 0, 0.25);
  }
}
