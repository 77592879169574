@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-root {
  &-hero {
    position: relative;

    &__title {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__img {
      width: 100%;
      object-fit: contain;
      border-bottom-right-radius: 48px;
      border-bottom-left-radius: 48px;
      box-shadow: $img-shadow;
    }
  }

  &-products {
    margin-left: auto;
    margin-right: auto;
    &__img {
      width: 100%;
      object-fit: contain;
      border-radius: 16px;
      box-shadow: $img-shadow;
    }
  }

  &__margin-auto {
    margin-left: auto;
    margin-right: auto;
  }

  &-why {
    &__img {
      height: 600px;

      @include mobileScreen {
        height: 400px;
      }
    }
    &__content-options {
      &-img {
        width: 70px;
        object-fit: contain;
      }
    }
  }

  &-whatShare {
    border-top-left-radius: 24px !important;
    padding: 48px 36px;
    &__img {
      height: 400px;
      object-fit: contain;
    }
    &__content-options {
      &-img {
        width: 70px;
        object-fit: contain;
      }
    }
  }

  &-backgroundColor {
    background-color: #ffe9d4;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;

    border-radius: 36px;

    border-bottom-right-radius: 250px;
    border-top-left-radius: 160px;

    @include mobileScreen {
      border-bottom-right-radius: 80px;
      border-top-left-radius: 100px;
    }
  }
}
