@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-course {
  width: 100%;
  height: 100%;

  background: url(../../assets/images/bg-white.webp) no-repeat right top, $color-primary-background;

  &-container {
    width: 100%;
    max-width: 860px;
    margin: 0 auto 36px auto;
    background-color: $color-white;

    padding: 16px 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 8px rgba(137, 137, 137, 0.25);
  }
  &-body {
    width: 100%;

    &__img {
      width: 90%;
      margin: 0 auto;

      & img {
        border-radius: 16px;
        transition: all 0.3s ease;
        box-shadow: 2px 14px 12px -9px rgba(0, 0, 0, 0.3);

        &:hover {
          transform: scale(1.02);
        }
      }

      &.record-thumbnail {
        position: relative;

        & img {
          filter: blur(1px);
        }

        & i {
          position: absolute;
          bottom: 8px;
          left: 24px;

          @include mobileScreen {
            bottom: 0px;
            left: 16px;
          }
        }
      }
    }
  }
}
