@use './mixin' as *;
@use './vars' as *;

.react-mui-tabs {
  width: 100%;
  border-bottom: 1px solid #bdbdbd;

  &-indicator {
    height: 4px !important;
  }

  &-tab {
    font-size: 16px !important;
    color: #757575 !important;
    text-transform: capitalize !important;
    font-weight: 600;

    &-selected {
      color: $color-primary !important;
    }
  }
}
