@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?e3s82w');
  src: url('fonts/icomoon.eot?e3s82w#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?e3s82w') format('truetype'), url('fonts/icomoon.woff?e3s82w') format('woff'),
    url('fonts/icomoon.svg?e3s82w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_questionare:before {
  content: '\e933';
}
.icon-ic_test:before {
  content: '\e934';
}
.icon-ic_approved:before {
  content: '\e932';
}
.icon-ic_attention:before {
  content: '\e931';
}
.icon-ic_protection:before {
  content: '\e930';
}
.icon-ic_assessment:before {
  content: '\e900';
}
.icon-ic_dollar:before {
  content: '\e901';
}
.icon-ic_headphones:before {
  content: '\e902';
}
.icon-ic_help:before {
  content: '\e903';
}
.icon-ic_home:before {
  content: '\e904';
}
.icon-ic_location:before {
  content: '\e905';
}
.icon-ic_noti:before {
  content: '\e906';
}
.icon-ic_arrow_left:before {
  content: '\e907';
}
.icon-ic_arrow_right:before {
  content: '\e908';
}
.icon-ic_arrow-down:before {
  content: '\e909';
}
.icon-ic_arrow-up:before {
  content: '\e90a';
}
.icon-ic_calendar:before {
  content: '\e90b';
}
.icon-ic_check:before {
  content: '\e90c';
}
.icon-ic_chevron-down:before {
  content: '\e90d';
}
.icon-ic_chevron-left:before {
  content: '\e90e';
}
.icon-ic_chevron-right:before {
  content: '\e90f';
}
.icon-ic_chevrons-left:before {
  content: '\e910';
}
.icon-ic_chevrons-right:before {
  content: '\e911';
}
.icon-ic_clock:before {
  content: '\e912';
}
.icon-ic_close:before {
  content: '\e913';
}
.icon-ic_conversation:before {
  content: '\e914';
}
.icon-ic_download:before {
  content: '\e915';
}
.icon-ic_edit:before {
  content: '\e916';
}
.icon-ic_eye-off:before {
  content: '\e917';
}
.icon-ic_fax:before {
  content: '\e918';
}
.icon-ic_filter:before {
  content: '\e919';
}
.icon-ic_grid:before {
  content: '\e91a';
}
.icon-ic_image:before {
  content: '\e91b';
}
.icon-ic_info:before {
  content: '\e91c';
}
.icon-ic_list:before {
  content: '\e91d';
}
.icon-ic_mail:before {
  content: '\e91e';
}
.icon-ic_map-pin:before {
  content: '\e91f';
}
.icon-ic_menu:before {
  content: '\e920';
}
.icon-ic_more-vertical:before {
  content: '\e921';
}
.icon-ic_mouse_pointer:before {
  content: '\e922';
}
.icon-ic_move:before {
  content: '\e923';
}
.icon-ic_paperclip:before {
  content: '\e924';
}
.icon-ic_password_view:before {
  content: '\e925';
}
.icon-ic_permission:before {
  content: '\e926';
}
.icon-ic_plus:before {
  content: '\e927';
}
.icon-ic_repos:before {
  content: '\e928';
}
.icon-ic_search:before {
  content: '\e929';
}
.icon-ic_shuffle:before {
  content: '\e92a';
}
.icon-ic_sort:before {
  content: '\e92b';
}
.icon-ic_thumbs-down:before {
  content: '\e92c';
}
.icon-ic_thumbs-up:before {
  content: '\e92d';
}
.icon-ic_trash:before {
  content: '\e92e';
}
.icon-ic_user:before {
  content: '\e92f';
}
